import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Outdoor_Cameras/IN-7011_HD/NavButtons';
import TableFeatures from 'components/Outdoor_Cameras/IN-7011_HD/TableFeatures';
import CompareOutdoor from 'components/Products/Compare/CompareOutdoorFlyout';
import OutdoorData from 'components/Products/Flyouts/Graphs/OutdoorGraphsFlyout';
import RadarGraphCard from 'components/Products/RadarGraphs/in7011-radargraph-grid';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-7011 HD",
  "path": "/Outdoor_Cameras/IN-7011_HD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-7011 HD Outdoor IP Camera",
  "image": "../P_SearchThumb_IN-7011HD.png",
  "social": "/images/Search/P_SearchThumb_IN-7011HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-7011HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-7011 HD' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-7011 HD Outdoor IP Camera' image='/images/Search/P_SearchThumb_IN-7011HD.png' twitter='/images/Search/P_SearchThumb_IN-7011HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-7011_HD/' locationFR='/fr/Outdoor_Cameras/IN-7011_HD/' crumbLabel="IN-7011" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-7011-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-7011-hd",
        "aria-label": "in 7011 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-7011 HD`}</h1>
    <CompareOutdoor mdxType="CompareOutdoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/573d3/IN-7011_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABvklEQVQoz32SyU4CQRCG5wlMfA+96Ml44KAXX8DEk4m8A1eXcPBJNEFciIpE45qgLGLGPYLAbKKCM4yADsz2m+6xcYzLn3RSU1399T9dxbmuC7KIDMNArfZCY3/etu1uTOQ4DhTlgeaZ2C7HDhNpWh35/P1X0T9ASVYgyw8QBBGvjQZkRUahUATHnJBVqTxBECW8vxu/Atl3s9midZIk4/HxCaenZ7i6ukEqnfWARLqu4+b2DpqmQ1Eq8IsAiSsGK5UEPD9XqQFyuaqqyGZzuLi89oCW7eC+WMJdvoB0OgNJUlCv678CRVGCKClotd6gqhrKZZHuJxLb2N7Z897wtdFE8jhNHSaTJygWy9SF/5cZsCyIiESi2N09RCwWRy7H0zzPnyOVynhA0t31jTgymRwFEej5xeUPh+yC4FQQPT29GB0ZQ6368u15OKNj0mBzK4H+/j4MDA4gEAigWq11G+N3aJomeJ5HKBTCwsIi7M+87bi0hutYFmwXMB0X0zMzGB4eQnQ52h0P5tCyvJk72D/C3GwYkcgSwuF5jE9MIrq6BtOxf86hX38NNnFIOk3UbrexvBLD4XESb502BX4AlTHhCHtITFEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92e08a1bfcf1176787baa3e5eb1918ef/e4706/IN-7011_Overview_width1650.avif 230w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/d1af7/IN-7011_Overview_width1650.avif 460w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/7f308/IN-7011_Overview_width1650.avif 920w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/e1c99/IN-7011_Overview_width1650.avif 1380w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/e02f7/IN-7011_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/92e08a1bfcf1176787baa3e5eb1918ef/a0b58/IN-7011_Overview_width1650.webp 230w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/bc10c/IN-7011_Overview_width1650.webp 460w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/966d8/IN-7011_Overview_width1650.webp 920w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/445df/IN-7011_Overview_width1650.webp 1380w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/44758/IN-7011_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92e08a1bfcf1176787baa3e5eb1918ef/81c8e/IN-7011_Overview_width1650.png 230w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/08a84/IN-7011_Overview_width1650.png 460w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/c0255/IN-7011_Overview_width1650.png 920w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/b1001/IN-7011_Overview_width1650.png 1380w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/573d3/IN-7011_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/c0255/IN-7011_Overview_width1650.png",
              "alt": "INSTAR IN-7011 HD",
              "title": "INSTAR IN-7011 HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-7011 HD is a weather proof dome camera which can either be used in in- or outdoor environments. A convenient motor allows for quick readjusting of the lenses viewing direction and can be controlled by any network device such as a tablet or a smartphone. The built-in infrared diodes allows you to see in pitch dark environments, while the integrated IR cut filter aids in the display of vibrant colours, as seen by the human eye. Through the use of an efficient h.264 compression codec it is able of recording video files on a `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`MicroSD Memory Card`}</Link>{` with comparably small size but best picture results. The sturdy construction of the camera body guarantees maximum durability and is weather proof for outdoor use.`}</p>
    <p>{`For surveillance purposes, the IN-7011 HD is ready for implementation to your existing system. But also as a stand-alone device it offers you a number of alarming features like `}<Link to="/Products/IN-Motion/" mdxType="Link">{`Email Notification`}</Link>{` or compatibility with `}<Link to="/Products/IN-Motion/" mdxType="Link">{`Signalling Devices`}</Link>{`.`}</p>
    <OutdoorData mdxType="OutdoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Wide Dynamic Range (WDR) 720p `}<Link to="/Outdoor_Cameras/IN-7011_HD/Technical_Specifications/" mdxType="Link">{`Omnivision CMOS Sensor`}</Link></li>
      <li parentName="ul">{`High quality aluminium housing`}</li>
      <li parentName="ul">{`Remote controlled Pan & Tilt - 350° pan and 100° tilt`}</li>
      <li parentName="ul">{`Nightvision with 22 Infrared LED´s @ 850nm (barely visible for the human eye) for distances up to 8 - 12m`}</li>
      <li parentName="ul">{`Alarm I/O for external sensors (e.g. `}<Link to="/Products/IN-Motion/" mdxType="Link">{`Motion`}</Link>{` or smoke detectors) and receivers (e.g. house automations)`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with 2.4 GHz `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`2.4 GHz WiFi`}</Link>{` IEEE 802.11 b/g/n up to 54Mbit networks with WEP, WPA and WPA2 security`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Network/IP_Config/" mdxType="Link">DHCP</Link> and <Link to="/Web_User_Interface/720p_Series/Network/UPnP/" mdxType="Link">UPnP</Link> support for an automatic network integration
      </li>
      <li parentName="ul">{`Integrated personal, free DDNS ("Internet") Address and P2P User ID to access your camera through the Internet`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/System/User/" mdxType="Link">Multi user management</Link> and simultaneous login for 3 users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/720p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> email notification</Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record videos directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link>
      </li>
    </ul>
    <p>{`Many built-in features allow you to use this camera not only for surveillance, but also for your personal projects. The `}<Link to="/Motion_Detection/Alarm_FTP_Upload/" mdxType="Link">{`FTP Support`}</Link>{` allows you to use the IN-7011 HD as a multi-functional outdoor webcam for integration on your website.`}</p>
    <RadarGraphCard mdxType="RadarGraphCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The 1/3 inch WDR sensor is built on a 4.3 micron OmniPixel3-HS™ Pixel enabling 720p HD at unparalleled low-light sensitivity and a dynamic range in colour of 115 dB – compared to the 60 db of a regular VGA IP Camera. WDR allows you to capture high detailed colour videos even in high-contrast environmental lighting conditions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      